import React from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { Card } from '@tremor/react';
import HeaderTitle from '../HeaderTitle';
import { useThemeStore } from '../../store/useThemeStore';
import NoData from '../../assets/images/NoData.png';

interface Props {
  selectedClient?: any;
}

const AccountManager: React.FC<Props> = ({ selectedClient }) => {
  const { mode } = useThemeStore((state) => state);
  const iconColor = mode === 'dark' ? 'white' : '#001C44';

  const am = selectedClient?.users?.filter(
    (user: any) => user?.role === 'am'
  )[0];
  const name = am?.first_name + ' ' + am?.last_name;
  const pic = am?.profile_pic;
  const email = am?.email;
  const phone =
    selectedClient?.clientCountry === 'United States'
      ? '(855) 871 5474'
      : selectedClient?.clientCountry === 'United Arab Emirates'
        ? '800 0120140'
        : '1300 433 071';
  const noAM = !name?.includes('undefined');
  const formatedName = noAM ? name : 'No Account Manager yet';
  const sendEmail = () => {
    window.open(`mailto:${email}`, '_blank');
  };
  return (
    <Card className="flex flex-col p-[16px] sm:p-6 gap-y-4 w-full h-fit lg:h-[456px] pr-0 mb-0 md:mb-8 lg:mb-8 xl:mb-0 dark:bg-[#222732] pb-2">
      {selectedClient?.loading ? (
        <div
          role="status"
          className="max-w-sm animate-pulse border-b min-h-[200px]"
        >
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5" />
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5" />
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]" />
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          {am === undefined ? (
            <div className="flex justify-between border-b pb-3 relative w-full h-fit lg:h-[200px]  font-inter">
              <div className="flex flex-col gap-y-2 lg:h-[160px]">
                <HeaderTitle
                  text="Key OMG Contacts"
                  fontSize="text-[18px] sm:text-[26px]"
                  lineColorLight="bg-[#ADF8FF]"
                />
                <div className="flex flex-col justify-center w-full items-center mt-6 sm:mt-0 gap-2">
                  <div>
                    <img src={NoData} alt="No Data" width={60} />
                  </div>
                  <div className="text-gray-500 text-sm mt-1 text-center  pb-6 lg:pb-0 md:w-full px-8">
                    <p className="font-bold text-md dark:text-[#FFFFFF]">
                      Please check back soon
                    </p>{' '}
                    <span className="dark:text-[#FFFFFF] text-xs sm:text-sm">
                      We're in the process of selecting the perfect Account
                      Manager for you. Please check back soon; the system will
                      assign a dedicated professional to this role shortly.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-between border-b pb-8 md:pb-3 relative md:h-[250px] sm:h-[200px]">
              <div className="flex flex-col gap-y-2">
                <HeaderTitle
                  text="Key OMG Contacts"
                  fontSize="text-[18px] sm:text-[26px] text-[#001C44]"
                  lineColorLight="bg-[#ADF8FF]"
                />
                <span className="text-[20px] sm:text-[30px] font-semibold text-[#001C44] dark:text-white ">
                  {formatedName}
                </span>
                <div className="flex items-baseline gap-x-2 text-xs md:text-sm lg:text-sm xl:text-sm  text-gray-500  mt-1">
                  <Icon name="user" color={iconColor} />{' '}
                  <span className="dark:text-[#FFFFFF]"> Account Manager</span>
                </div>
                <div className="flex items-center  text-gray-500 text-xs md:text-sm lg:text-sm xl:text-sm  gap-x-2 text-wrap">
                  <Icon name="email" color={iconColor} />{' '}
                  <span className="max-w-[180px] sm:max-w-full truncate dark:text-[#FFFFFF]">
                    {' '}
                    {email}
                  </span>{' '}
                </div>
                <div className="flex items-center text-gray-500 text-xs md:text-sm lg:text-sm xl:text-sm gap-x-2">
                  <Icon name="phone" color={iconColor} />{' '}
                  <span className="dark:text-[#FFFFFF]">{phone}</span>{' '}
                </div>
              </div>
              {pic ? (
                <img
                  src={pic}
                  alt="Account Manager"
                  className="absolute right-6 bottom-3 sm:right-6 sm:bottom-8 block h-[120px] lg:w-[120px] lg:h-[142px]"
                />
              ) : (
                <div className="absolute right-0 bottom-3 sm:right-4 sm:bottom-6 block">
                  <Icon name="NoAccountManagerImage" color={iconColor} />
                </div>
              )}
            </div>
          )}
        </>
      )}
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2 pb-4 md:pb-2">
          <div>
            <div className="font-palmer text-[#0029FF] text-[28px] dark:text-[#009EFF] uppercase">
              Got Campaign Feedback?
            </div>
            <div className="text-[20px] sm:text-[30px] font-semibold text-[#001C44] dark:text-white">
              Simon Hancock
            </div>
          </div>
          <div className="flex items-baseline gap-x-2 text-gray-500 text-xs md:text-sm lg:text-sm xl:text-sm  mt-1">
            <Icon name="user" color={iconColor} />{' '}
            <span className="dark:text-[#FFFFFF]"> Chief Customer Officer</span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-500 text-xs md:text-sm lg:text-sm xl:text-sm  ">
            <Icon name="email" color={iconColor} />{' '}
            <span className="max-w-[180px] sm:max-w-full truncate dark:text-[#FFFFFF]">
              {' '}
              simon@onlinemarketinggurus.com.au
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-500 text-xs md:text-sm lg:text-sm xl:text-sm  ">
            <Icon name="phone" color={iconColor} />{' '}
            <span className="dark:text-[#FFFFFF]">0423 292 292</span>{' '}
          </div>
        </div>
        <div className="flex items-center justify-between p-8">
          <img
            src="https://gurulytics-assets.s3.ap-southeast-2.amazonaws.com/users/simon@onlinemarketinggurus.com.au.png"
            alt="simon"
            className="absolute right-6 bottom-6 sm:right-12 sm:bottom-8 block h-[120px] lg:w-[120px] lg:h-[142px]"
          />
        </div>
      </div>
    </Card>
  );
};

export default AccountManager;
