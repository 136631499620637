import Header from '../components/Layout/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import useAuthStore from '../store/useAuthStore';
import { useNavigate } from 'react-router';
import Sidebar from '../components/Layout/Sidebar';
import { useThemeStore } from '../store/useThemeStore';
import Icon from '../assets/icons/SvgComponent';
import normalizeWheel from 'normalize-wheel';
import useUtilityStore from '../store/useUtilityStore';
interface SubItem {
  label: string;
  path: string;
}
interface Item {
  icon: string;
  label: string;
  path: string;
  subItems?: SubItem[];
}

interface UtilityStoreState {
  setIsHeaderHidden: (selectedOption: any) => void;
}
const Layout = () => {
  const { setIsHeaderHidden } = useUtilityStore() as UtilityStoreState;
  const mode = useThemeStore((state) => state.mode);
  const location = useLocation();
  const { loginAsUser, user } = useAuthStore((state) => state);
  const overDuePayment = false;
  const navigate = useNavigate();
  const items: Item[] = [
    {
      icon: 'dashboard',
      label: 'Dashboard',
      path: '/dashboard',
    },
    {
      icon: 'analytics',
      label: 'Analytics',
      path: '/analytics/seo-ga4',
    },
    {
      icon: 'reports',
      label: 'Reports',
      path: '/reports',
    },
    // {
    //   icon: 'goals',
    //   label: 'Goals',
    //   path: '/goals',
    // },

    // {
    //   icon: 'referrals',
    //   label: 'Referrals',
    //   path: '/referrals',
    // },
    // {
    //   icon: 'chat',
    //   label: 'Chat',
    //   path: '/chat',
    // },
    // {
    //   icon: 'feedback',
    //   label: 'Feedback',
    //   path: '/feedback',
    // },
    {
      icon: 'settings',
      label: 'Settings',
      path: '/settings/my-details',
    },
  ];
  useEffect(() => {
    if (!loginAsUser && user?.role?.level === 'omg') {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (mode === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [mode]);

  const [prevTouchY, setPrevTouchY] = useState(null);

  const handleScroll = (e) => {
    e.stopPropagation();
    // desktop scroll
    if (e.type === 'wheel' || e.type === 'scroll') {
      const normalized = normalizeWheel(e);
      let scrollTop = normalized.pixelY;
      if (scrollTop > 0) {
        setIsHeaderHidden(true);
      } else if (scrollTop < 0) {
        setIsHeaderHidden(false);
      }
    }
    // mobile scroll
    else if (e.type === 'touchmove') {
      const touch = e.changedTouches[0];
      const currentTouchY = touch.clientY;

      if (prevTouchY !== null) {
        if (currentTouchY > prevTouchY) {
          setIsHeaderHidden(false);
        } else if (currentTouchY < prevTouchY) {
          setIsHeaderHidden(true);
        }
      }
      setPrevTouchY(currentTouchY);
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.changedTouches[0];
    const currentTouchY = touch.clientY;
    setPrevTouchY(currentTouchY);
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const currentTouchY = touch.clientY;
    setPrevTouchY(currentTouchY);
  };
  return (
    <div className="flex h-full mx-auto relative max-w-[1920px] bg-[#D2EEFF] dark:bg-[#0C111D]">
      <Helmet>
        <title>Gurulytics</title>
        <meta name="description" content="Gurulytics" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="max-h-screen dark:bg-[#0C111D] bg-[#D2EEFF]">
        <div className="relative z-[99]">
          <Sidebar items={items} />
          {/* <div
          className="absolute top-[16px] right-[-15px] z-[100] cursor-pointer md:block lg:block sm:hidden"
          onClick={() => toggleSidebar()}
        >
          {!sidebarCollapsed ? (
            <div className="flex flex-col items-center gap-[2px] justify-center sm:hidden md:flex lg:flex rounded-full bg-[#F3F7FA] h-8 w-8 dark:bg-gray-700">
              <Icon name="ToggleDash" color={iconColor} />
              <Icon name="ToggleDashArrowRight" color={iconColor} />
              <Icon name="ToggleDash" color={iconColor} />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-[2px] justify-center sm:hidden md:flex lg:flex rounded-full bg-[#F3F7FA] h-8 w-8 dark:bg-gray-700">
              <Icon name="ToggleDash" color={iconColor} />
              <Icon name="ToggleDashArrowLeft" color={iconColor} />
              <Icon name="ToggleDash" color={iconColor} />
            </div>
          )}
        </div> */}
        </div>
      </div>
      <div // desktop
        // onWheel={(e) => handleScroll(e)}
        // onScroll={(e) => handleScroll(e)}
        // mobile
        onTouchMove={(e) => handleScroll(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
        className="flex-col w-full overflow-auto dark:bg-[#0C111D] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8"
      >
        {!location.pathname.includes('dashboard') ? (
          <Header />
        ) : (
          <></>
          // <div className="flex items-center justify-center w-full border-b h-[50px] px-6 pt-8 sticky top-0 bg-[#D2EEFF] z-[99] dark:bg-[#0C111D] border-none">
          //   <div className="bg-[#001C44] dark:bg-[#1F242F] w-full h-12 rounded-lg flex justify-center items-center text-[#FFB545] font-inter font-[600]">
          //     Overdue Payment - View invoice and pay
          //   </div>
          // </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
