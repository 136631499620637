import * as React from "react"

const AreaIconChart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#0029FF"
      d="M1.813 1.281v9.625c0 .383.273.656.656.656h11.375c.355 0 .656.301.656.657 0 .383-.3.656-.656.656H2.469A1.96 1.96 0 0 1 .5 10.906V1.281c0-.355.273-.656.656-.656.356 0 .657.3.657.656ZM8.32 5.93 6.625 4.234 4.437 6.422v2.516h7.875V6.804L10.918 5.19l-.766.739a1.287 1.287 0 0 1-1.832 0ZM9.25 5l.902-.902.164-.164a.885.885 0 0 1 1.286.054l1.695 1.969c.191.246.328.547.328.848v2.57c0 .492-.41.875-.875.875H4a.864.864 0 0 1-.875-.875V6.422c0-.328.137-.684.383-.93l2.488-2.488a.892.892 0 0 1 1.23 0L8.32 4.098 9.25 5Z"
    />
  </svg>
)
export default AreaIconChart
