import { DonutChart } from '../../DonutChart';

interface IProps {
  data?: any;
  category?: string;
}

const PieChartDisplay = ({ data, category }: IProps) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <DonutChart
        data={data}
        variant="pie"
        category={category}
        value="amount"
        valueFormatter={(number: number) =>
          `${Intl.NumberFormat('us').format(number).toString()}`
        }
      />
    </div>
  );
};

export default PieChartDisplay;
