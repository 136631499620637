import Icon from '../../assets/icons/SvgComponent';
import ReactSelect from '../../components/ReactSelect';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmStatus from '../../components/Modals/UserModal/confirmationStatus';
import Alert from '../../components/Alert';
import { useThemeStore } from '../../store/useThemeStore';
import TanStackTable from '../../components/TanStackTable';
import { getRoleName } from '../../utils';
import ImageIcon from '../../components/ImageIcon';
import EditUserModal from '../../components/Modals/UserModal/editUserModal';
import CustomModal from '../../components/CustomModal';
import { useState, useMemo } from 'react';
import useAuthStore from '../../store/useAuthStore';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { Grid, Col, Card } from '@tremor/react';
import { getBadgeColorAndText } from '../../utils/common';

interface UserProps {
  selectedClient?: any;
  updateUserStatus?: any;
  isLoading?: any;
}

const User: React.FC<UserProps> = ({
  selectedClient,
  updateUserStatus,
  isLoading,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const { loginAsUser, loginAsClient, user } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [clientDetails, setClientDetails] = useState(true);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState({});

  const type = (selectedUser) => {
    if (selectedUser === undefined) {
      return '';
    }
    if (selectedUser?.gurulytics_role === 'own' || 'stf') {
      return 'users';
    } else {
      return 'omg';
    }
  };

  const handleLoginAs = async (id) => {
    try {
      const data = await api.User.getUser(id);

      if (user.role.level === 'omg') {
        loginAsClient(data);
        navigate(`/dashboard`);
      } else {
        // Handle the case where user.role.level is not 'omg'
        alert('Invalid user role level');
      }
    } catch (error) {
      console.log(error);
      toast.error(
        <Alert
          icon=""
          title={`Client Error`}
          message={`Sorry, this user doesn't have any clients. Add a client to proceed`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleToggleStatus = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    updateUserStatus({ ids: id, status: newStatus });
    toast.success(
      <Alert
        icon=""
        title={`CLIENT USER STATUS`}
        message={`Client User's status been changed successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setConfirmationStatus(false);
    setConfirmStatus({});
  };

  let formattedUsers = useMemo(() => {
    if (
      !selectedClient ||
      !selectedClient?.users ||
      !Array.isArray(selectedClient?.users)
    ) {
      return [];
    }
    return selectedClient?.users
      ?.filter((item: any) => ['own', 'stf'].includes(item.role))
      .map((user: any) => ({
        nameAndEmail: {
          data: {
            name: user?.last_name
              ? `${user.first_name} ${user.last_name}`
              : user?.first_name || '',
          },
          name:
            `${user?.first_name} ${user.last_name ? user.last_name : ''}` || '',
          email: user?.email || '',
        },
        role: user?.role || '',
        isEnabled: user,
        user,
      }));
  }, [selectedClient]);

  // const columns = [
  //   {
  //     id: 'Name and Email',
  //     header: () => (
  //       <div className="w-full flex flex-start pl-3">Name and Email</div>
  //     ),
  //     accessorKey: 'nameAndEmail',
  //     cell: (props: any) => {
  //       return (
  //         <div className="text-sm  flex gap-2 items-center w-[200px] sm:w-[40vw]  ml-2">
  //           <div>
  //             <ImageIcon
  //               data={props.getValue()?.data}
  //               size={8}
  //               textSize={'xs'}
  //             />
  //           </div>
  //           <div>
  //             <div className="font-semibold text-left w-[200px] sm:w-[40vw] truncate">
  //               {props.getValue()?.name}
  //             </div>
  //             <div className="font-base text-left w-[200px] sm:w-[40vw] truncate">
  //               {props.getValue()?.email}
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     id: 'Role',
  //     header: 'Role',
  //     accessorKey: 'role',
  //     cell: (props: any) => (
  //       <div className="flex items-center gap-2 justify-center w-[200px] sm:w-full">
  //         <span className="text-xs rounded-full border max-w-fit bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000] font-inter">
  //           {getRoleName(props.getValue())}
  //         </span>
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 'Status',
  //     header: () => (
  //       <div className="w-[100px] sm:w-[4vw] flex justify-center">Status</div>
  //     ),
  //     accessorKey: 'isEnabled',
  //     cell: (props: any) => (
  //       <div className="flex items-center gap-2 justify-center w-[100px] sm:w-[4vw]">
  //         <label className="relative inline-flex items-center cursor-pointer">
  //           <input
  //             type="checkbox"
  //             className="sr-only peer"
  //             defaultChecked={props.getValue()?.status === 'active'}
  //             value={props.getValue()?.status}
  //             onClick={() => {
  //               setConfirmStatus(props.getValue());
  //               setConfirmationStatus(true);
  //             }}
  //           />
  //           <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
  //         </label>
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 'Action',
  //     header: () => (
  //       <div className="w-[100px] sm:w-[3vw] flex justify-center">Action</div>
  //     ),
  //     accessorKey: 'isEnabled',
  //     cell: (props: any) => (
  //       <div className="flex gap-4 w-[100px] sm:w-[3vw] justify-center cursor-pointer">
  //         <div
  //           onClick={() => {
  //             setSelectedUser(props.getValue());
  //             setOpenEditModal(true);
  //           }}
  //         >
  //           <Icon
  //             name="UserEditIcon"
  //             color={mode === 'dark' ? '#fff' : iconColor}
  //           />
  //         </div>
  //         <div onClick={() => handleLoginAs(props.getValue()?.id)}>
  //           <Icon
  //             name="Login"
  //             size={20}
  //             color={mode === 'dark' ? '#fff' : iconColor}
  //           />
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <div className="w-full px-1 mb-[32px]  rounded-lg">
      {isLoading ? (
        <Grid
          numItems={1}
          numItemsSm={2}
          numItemsMd={3}
          numItemsLg={4}
          className="pt-2 gap-6"
        >
          <Col>
            <Card className="w-full h-[281px] bg-gray-300 animate-pulse"></Card>
          </Col>
          <Col>
            <Card className="w-full h-[281px] bg-gray-300 animate-pulse"></Card>
          </Col>
          <Col>
            <Card className="w-full h-[281px] bg-gray-300 animate-pulse"></Card>
          </Col>
          <Col>
            <Card className="w-full h-[281px] bg-gray-300 animate-pulse"></Card>
          </Col>
        </Grid>
      ) : (
        <>
          {formattedUsers.length > 0 ? (
            <Grid
              numItems={1}
              numItemsSm={2}
              numItemsMd={3}
              numItemsLg={4}
              className="gap-6"
            >
              {formattedUsers.map((user, index) => (
                <Col key={index}>
                  <Card className="flex flex-col justify-center items-center gap-4 dark:bg-[#222732]">
                    <div className="bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-1 w-fit rounded-full relative">
                      <ImageIcon
                        data={`${user?.user?.first_name} ${user?.user?.last_name}`}
                        size={20}
                        textSize={'2xl'}
                      />
                      <div className="bg-white p-[3px] w-fit rounded-full absolute right-[8px] bottom-[2px]">
                        <div className="w-3 h-3 bg-[#17B26A] rounded-full" />
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-3">
                      <div className="text-[18px] text-[#001C44] font-[700] dark:text-white">
                        {user?.user?.first_name}
                        {` `}
                        {user?.user?.last_name}
                      </div>
                      <div className="text-[#001C4480] text-xs dark:text-gray-300">
                        {user?.user?.email}
                      </div>
                      <div
                        className={`uppercase ${getBadgeColorAndText(user?.user?.role)?.color} px-[8px] py-[5px] rounded-[8px] font-[500] text-[12px]`}
                      >
                        {getBadgeColorAndText(user?.user?.role)?.text}
                      </div>
                    </div>

                    <div className="flex justify-center gap-4 text-sm border-t-2 border-dashed w-full pt-3">
                      <div
                        className="border-r pr-4 cursor-pointer dark:text-gray-300"
                        onClick={() => {
                          setSelectedUser(user?.user);
                          setOpenEditModal(true);
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="text-[#0029FF] cursor-pointer dark:text-[#009EFF]"
                        onClick={() => handleLoginAs(user?.user?.id)}
                      >
                        Login as Client
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
              {/* <TanStackTable
          data={formattedUsers}
          columns={columns}
          totalPages={1}
          loading={isLoading}
          page={1}
          setPage={1}
          tableHeight="h-[60dvh]"
          setSelectedRow={setSelectedRow}
        /> */}
            </Grid>
          ) : (
            <div className="bg-white w-full flex justify-center items-center h-[600px] rounded-lg">
              <div className="text-[18px] text-[#001C44] font-[700] dark:text-white">
                Currently you don't have any users
              </div>
            </div>
          )}
        </>
      )}
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditUserModal
          user={selectedUser}
          type=""
          clientDetails={clientDetails}
          onClose={() => setOpenEditModal(false)}
        />
      </CustomModal>
      <CustomModal
        open={confirmationStatus}
        onClose={() => setConfirmationStatus(false)}
      >
        <ConfirmStatus
          user={confirmStatus}
          onClose={() => setConfirmationStatus(false)}
          action={handleToggleStatus}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default User;
