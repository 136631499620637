import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { Wrapper } from './styles';
import Select, {
  ActionMeta,
  StylesConfig,
  ControlProps,
  GroupBase,
  CSSObjectWithLabel,
} from 'react-select';
import moment, { Moment } from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import useClientStore from '../../store/useClientStore';

interface DateRangeCompProps {
  disabled: boolean;
  cycle?: number;
  style?: CSSProperties;
}

interface OptionType {
  label: string;
  value: Moment;
}

const DateRangeComp: FunctionComponent<DateRangeCompProps> = ({
  disabled,
  style,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedClient } = useClientStore((state) => state);

  const isCycle2 =
    (selectedClient?.cycle?.toLowerCase() === 'cycle 2' ||
      selectedClient?.cycle === 'Cycle 2') &&
    location.pathname.includes('/analytics/seo-ga4');

  const getDefaultRange = (): [Moment, Moment] => {
    const current = moment();
    if (!isCycle2) {
      const start = current.clone().startOf('month');
      const end = current.clone().endOf('month');
      return [start, end];
    } else {
      if (current.date() <= 14) {
        const end = current.clone().date(14);
        const start = end.clone().subtract(1, 'month').add(1, 'day');
        return [start, end];
      } else {
        const start = current.clone().date(15);
        const end = start.clone().add(1, 'month').subtract(1, 'day');
        return [start, end];
      }
    }
  };

  const getSelectedOptionFromUrl = (): OptionType | null => {
    const searchParams = new URLSearchParams(location.search);
    const endDateParam =
      searchParams.get('endDate') ||
      JSON.parse(localStorage.getItem('seoEndDate'));

    // Define default endDate based on current month
    const [, defaultEndDate] = getDefaultRange();

    // If endDateParam exists, override the defaultEndDate
    const endDate = endDateParam
      ? moment(endDateParam, 'YYYY-MM-DD')
      : defaultEndDate;
    return (
      options.find((option) => moment(option.value).isSame(endDate, 'day')) ||
      null
    );
  };

  const options = useMemo<OptionType[]>(() => {
    const [, defaultEnd] = getDefaultRange();
    let dateRanges: OptionType[] = [];

    let start: Moment = !isCycle2 ? moment('2022-01-01') : moment('2022-01-15');
    let current = defaultEnd;

    // Monthly range calculations
    while (start.isSameOrBefore(current)) {
      let end = start.clone().add(1, 'months').subtract(1, 'days');
      dateRanges.push({
        label: `${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`,
        value: end,
      });
      start = start.clone().add(1, 'months');
    }

    return dateRanges.reverse();
  }, [isCycle2]);

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    getSelectedOptionFromUrl()
  );

  useEffect(() => {
    setSelectedOption(getSelectedOptionFromUrl());
  }, [location.search]);

  useEffect(() => {
    if (selectedOption) {
      const [startLabel, endLabel] = selectedOption.label.split(' - ');
      const startDate = moment(startLabel, 'DD MMM YYYY').toDate();
      const endDate = moment(endLabel, 'DD MMM YYYY').toDate();
      updateUrl(startDate, endDate);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!selectedClient?.loading) {
      const getLocalStorageDates = (startKey: string, endKey: string) => {
        const rawStart = localStorage?.getItem(startKey);
        const rawEnd = localStorage?.getItem(endKey);
        const start = rawStart ? JSON.parse(rawStart) : null;
        const end = rawEnd ? JSON.parse(rawEnd) : null;
        return {
          startDate: start && moment(start).format('DD MMM YYYY'),
          endDate: end && moment(end).format('DD MMM YYYY'),
        };
      };

      const setDefaultDatesForCycle2 = () => {
        const current = moment();
        if (current.date() <= 14) {
          return {
            startDate: current
              .clone()
              .subtract(1, 'month')
              .add(1, 'day')
              .format('DD MMM YYYY'),
            endDate: current.clone().date(14).format('DD MMM YYYY'),
          };
        } else {
          return {
            startDate: current.clone().date(15).format('DD MMM YYYY'),
            endDate: current
              .clone()
              .add(1, 'month')
              .date(14)
              .format('DD MMM YYYY'),
          };
        }
      };

      const setDefaultDates = () => {
        const prevMonth = moment().startOf('month');
        return {
          startDate: prevMonth.format('DD MMM YYYY'),
          endDate: prevMonth.clone().endOf('month').format('DD MMM YYYY'),
        };
      };

      let dates;
      if (isCycle2) {
        dates = getLocalStorageDates('seoStartDate', 'seoEndDate');
        const cycle1Change =
          selectedClient?.cycle?.toLowerCase() === 'cycle 1' &&
          dates.startDate &&
          moment(dates.startDate).date() === 15;

        const cycle2Change =
          selectedClient?.cycle?.toLowerCase() === 'cycle 2' &&
          dates.startDate &&
          moment(dates.startDate).date() === 1;
        const cycleChanged = cycle1Change && cycle2Change;
        if (!dates.startDate || !dates.endDate || cycleChanged) {
          dates = setDefaultDatesForCycle2();
        }
      }
      if (!isCycle2) {
        dates = getLocalStorageDates('startDate', 'endDate');
        if (!dates.startDate || !dates.endDate) {
          dates = setDefaultDates();
        }
      }
      const toFilterDate = `${dates.startDate} - ${dates.endDate}`;
      const filterDate = options.filter((f: any) => f.label === toFilterDate);
      setSelectedOption(filterDate[0]);
    }
  }, [options, location.pathname, isCycle2, selectedClient]);

  const updateUrl = (startDate: Date | null, endDate: Date | null) => {
    const searchParams = new URLSearchParams(location.search);
    if (startDate && endDate) {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

      searchParams.set('startDate', formattedStartDate);
      searchParams.set('endDate', formattedEndDate);
    } else {
      searchParams.delete('startDate');
      searchParams.delete('endDate');
    }
    navigate({ search: searchParams?.toString() });
  };

  const handleDateChange = (
    option: OptionType | null,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (option) {
      const { label, value } = option;
      const [startLabel, endLabel] = label.split(' - ');
      const startDate = moment(startLabel, 'DD MMM YYYY').toDate();
      const endDate = moment(endLabel, 'DD MMM YYYY').toDate();

      setSelectedOption(option);
      updateUrl(startDate, endDate);
      // 01 Jul 2023 - 31 Jul 2023
      moment(startDate, 'DD MMM YYYY').toDate();
      moment(endDate, 'DD MMM YYYY').toDate();
      const isCycle2Client = selectedClient?.cycle?.toLowerCase() === 'cycle 2';
      if (isCycle2) {
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
      } else if (isCycle2Client && location.pathname !== '/analytics/seo-ga4') {
        localStorage.setItem('startDate', JSON.stringify(startDate));
        localStorage.setItem('endDate', JSON.stringify(endDate));
      } else {
        localStorage.setItem('startDate', JSON.stringify(startDate));
        localStorage.setItem('endDate', JSON.stringify(endDate));
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
      }
    }
  };

  const customStyles: StylesConfig<OptionType, false> = {
    control: (
      base: CSSObjectWithLabel,
      props: ControlProps<OptionType, false, GroupBase<OptionType>>
    ) => ({
      ...base,
      width: 'fit-content',
      borderRadius: '8px',
      display: 'flex',
      borderColor: '#808EA2',
      cursor: 'pointer',
    }),
  };

  return (
    <Wrapper style={style}>
      <Select
        className="z-50 my-react-select-container"
        classNamePrefix="my-react-select w-[600px]"
        options={options}
        onChange={handleDateChange}
        isDisabled={disabled}
        isSearchable={false}
        styles={customStyles}
        value={selectedOption}
      />
    </Wrapper>
  );
};

export default DateRangeComp;
