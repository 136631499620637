import { Card } from '@tremor/react';

import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  layoutData: any;
  setSelectedItem: (data: any) => void;
  setOpenWidget: (value: boolean) => void;
  viewType?: string;
}

const EmptyGridItem = ({
  layoutData,
  setSelectedItem,
  setOpenWidget,
  viewType = 'view',
}: IProps) => {
  return (
    <Card className="border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-[410px] sm:h-[374px] overflow-hidden">
      <div className="flex items-center justify-end pb-[0.8rem] sm:pb-0">
        {/* <div className="flex items-center">
          {viewType === 'edit' && (
            <div
              onClick={(e) => {
                setOpenWidget(true);
                console.log(layoutData, 'layoutData');
                setSelectedItem(layoutData);
              }}
              className="block ml-2 cursor-pointer z-50 w-4 h-4 flex items-center"
            >
              <Icon name="gridMenu" />
            </div>
          )}
        </div> */}
      </div>
      <div className="h-full w-full flex justify-center items-center">
        <div className="p-12 rounded-full border border-[#EAECF0]">
          <div className="p-10 rounded-full border border-[#EAECF0]">
            <div className="p-8 rounded-full border border-[#EAECF0]">
              <div className="p-6 rounded-full border border-[#EAECF0]">
                <div className="p-4 bg-white rounded-full text-center">
                  <div
                    className="cursor-pointer border-2 border-dashed border-[#98A2B3] rounded-full p-3"
                    onClick={(e) => {
                      if(viewType === 'edit') {
                        setOpenWidget(true);
                        console.log(layoutData, 'layoutData');
                        setSelectedItem(layoutData);
                      }
                    }}
                  >
                    <Icon name="Plus" color="#98A2B3" size={9} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EmptyGridItem;
