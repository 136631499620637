import ComingSoon from '../ComingSoon';

const Billing: React.FC = () => {
  return (
    <div className="flex flex-col w-full h-full gap-8 p-8 bg-[#D2EEFF] dark:bg-[#191919]">
      <ComingSoon />
    </div>
  );
};

export default Billing;
