import React, { useState } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import useAuthStore from '../../store/useAuthStore';
interface IntegrationsProps {
  openModal?: any;
  integrationItems?: any;
  selectedClient?: any;
}

const IntegrationCard: React.FC<IntegrationsProps> = ({
  openModal,
  integrationItems,
  selectedClient,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const { setDomain } = useAuthStore((state) => state);
  const iconColor = mode === 'dark' ? 'white' : '#001C44';

  const navigateToAnalytics = (link: string) => {
    const domain = {
      id: selectedClient.id,
      label: selectedClient.name,
    };
    setDomain(domain);
    window.open(link, '_blank');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {integrationItems.map((integration: any, index: number) => (
        <div
          className="p-6 md:p-4 bg-white rounded-lg shadow dark:bg-gray-700 dark:border-gray-700"
          key={index}
        >
          <div className="flex items-center justify-between mb-8">
            <div className="bg-gray-100 p-2 rounded-lg">
              <img src={integration.image} alt="i" width={36} height={36} />
            </div>
            {['activated','active'].includes(integration?.status?.toLowerCase()) ? (
              <div>
                <button
                  type="button"
                  onClick={() => openModal(integration)}
                  className="flex gap-2 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Edit
                  <Icon
                    name="ArrowRight"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => openModal(integration)}
                  className=" flex gap-2 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  <Icon
                    name="ZapLine"
                    color={mode === 'dark' ? '#fff' : iconColor}
                  />
                  Connect
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between min-h-[200px]">
            <div>
              <h1 className="text-xl font-semibold mb-2 dark:text-[#fff]">
                {integration.title}
              </h1>
              <p className="text-sm font-base text-gray-600 mb-2 dark:text-gray-400">
                {integration.description}
              </p>
              {['activated','active'].includes(integration?.status?.toLowerCase()) ? (
                <button
                  onClick={() => openModal(integration)}
                  className={
                    'text-[#005C9F] flex gap-2 pb-12  dark:text-gray-300'
                  }
                >
                  Connected{' '}
                  <div
                    className={
                      integration.title === 'Semrush' ||
                      integration.title === 'Ahrefs'
                        ? 'hidden'
                        : ''
                    }
                  >
                    <Icon
                      name="ArrowRight"
                      color={mode === 'dark' ? '#fff' : '#005C9F'}
                    />{' '}
                  </div>
                  <span
                    className={
                      integration.title === 'Semrush' ||
                      integration.title === 'Ahrefs'
                        ? 'hidden'
                        : `text-sm ${integration.title === 'Google Search Console' ? 'w-36 truncate' : ''}`
                    }
                  >
                    {integration?.id}
                  </span>
                </button>
              ) : (
                <div className="pb-[71px]"></div>
                // <div className='mt-6 border-t pt-6'>
                //   <div className='flex items-center mb-4  rounded-full'>
                //     <input
                //       type='checkbox'
                //       value=''
                //       className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                //     />
                //     <label className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                //       I don’t wish to provide this access
                //     </label>
                //   </div>
                //   <div className='flex '>
                //     <input
                //       type='checkbox'
                //       value=''
                //       className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                //     />
                //     <label className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                //       I don’t have this, I need help obtaining this
                //     </label>
                //   </div>
                // </div>
              )}
            </div>
            <div>
              {['activated','active'].includes(integration?.status?.toLowerCase()) &&
                integration?.link?.length ? (
                <div
                  onClick={() => navigateToAnalytics(integration.link)}
                  className="text-right text-sm text-[#005C9F] underline cursor-pointer dark:text-gray-300"
                >
                  View Dashboard
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default IntegrationCard;
