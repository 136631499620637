import { BarChart } from '@tremor/react';


interface IProps {
  data?: any;
  category?: string[];
}

const BarChartDisplay = ({ data, category }: IProps) => {
  return (
    <div>
      <BarChart
        data={data}
        index="date"
        categories={category}
        colors={['#FFC265', '#7DCEFF', '#019AA3', '#00199D', '#002943']}
        showLegend={false}
        yAxisWidth={60}
        showYAxis={false}
        startEndOnly={true}
        className="-mb-2 mt-8 h-48"
      />
    </div>
  );
};

export default BarChartDisplay;
