import * as React from "react"


const PieIconChart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#0029FF"
      d="M12.98 6a4.839 4.839 0 0 0-3.855-3.855V6h3.855ZM9.125 7.313H7.812V1.215c0-.246.192-.465.438-.465 3.363 0 6.125 2.762 6.125 6.125 0 .246-.219.438-.465.438H9.125ZM5.57 9.555a1.302 1.302 0 0 1-.383-.93V3.238c-2.05.739-3.5 2.68-3.5 4.95a5.251 5.251 0 0 0 5.25 5.25c.739 0 1.45-.137 2.079-.41L5.57 9.554Zm.465-7.848c.246-.055.465.164.465.41v6.508l4.266 4.293c.191.191.164.492-.028.629a6.609 6.609 0 0 1-3.8 1.203A6.547 6.547 0 0 1 .375 8.187c0-3.308 2.46-6.042 5.66-6.48Zm8.723 6.918v.027c.246 0 .465.192.41.465A6.41 6.41 0 0 1 13.145 13a.423.423 0 0 1-.575-.027L8.25 8.625h6.508Z"
    />
  </svg>
)
export default PieIconChart
