import * as React from "react"

const LineIconChart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#0029FF"
      d="M1.813 1.281v9.625c0 .383.273.656.656.656h11.375c.355 0 .656.301.656.657 0 .383-.3.656-.656.656H2.469A1.96 1.96 0 0 1 .5 10.906V1.281c0-.355.273-.656.656-.656.356 0 .657.3.657.656Zm11.62 3.09L9.689 8.09a.599.599 0 0 1-.903 0l-2.16-2.16-2.16 2.16c-.274.273-.684.273-.93 0a.599.599 0 0 1 0-.902L6.16 4.563c.11-.137.274-.192.465-.192a.64.64 0 0 1 .465.191l2.16 2.16 3.254-3.253c.246-.274.656-.274.93 0a.644.644 0 0 1 0 .902Z"
    />
  </svg>
)
export default LineIconChart
