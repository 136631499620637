import useDashboardStore from '../../../store/useDashboardStore';

interface IProps {
  handleScroll: (id: string) => void;
  activeTab: any;
  setActiveTab: (selectedOption: any) => void;
}

const Tabs = ({ handleScroll, activeTab, setActiveTab }: IProps) => {
  const { sections, loading } = useDashboardStore((state) => state);

  return (
    <>
      {sections?.length > 0 && !loading ? (
        <div className="sticky sm:top-[190px] lg:top-[152px] bg-[#D2EEFF] z-[11] px-[2.5rem] dark:bg-[#0C111D]">
          <div className="hidden sm:block text-xs font-medium text-center text-gray-500 border-b border-[#fff] dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              {sections.map((tab, index) => (
                <li className="me-2" key={index}>
                  <a
                    href={`#${tab?.order || ''}`}
                    onClick={() => {
                      handleScroll(tab?.order || '');
                      setActiveTab(tab);
                    }}
                    className={
                      activeTab?.title === tab?.title
                        ? 'inline-block p-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                        : 'inline-block p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }
                  >
                    {tab?.title || 'Untitled'}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        !loading ? null : 
        <div className="sticky sm:top-[190px] lg:top-[152px] bg-[#D2EEFF] z-[11] px-[2.5rem] dark:bg-[#0C111D]">
          <div className="hidden sm:block text-xs font-medium text-center text-gray-500 border-b border-[#fff] dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              {Array.from({ length: 5 }).map((_, index) => (
                <li className="me-2 animate-pulse" key={index}>
                  <div className="inline-block p-2 w-24 h-6 bg-gray-300 rounded dark:bg-gray-700"></div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Tabs;
