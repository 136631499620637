import { LineChart } from '@tremor/react';

interface IProps {
  data?: any;
  category?: string[];
}

const LineChartDisplay = ({ data, category }: IProps) => {
  return (
    <div>
      <LineChart
        data={data}
        index="date"
        categories={category}
        showLegend={false}
        showYAxis={false}
        startEndOnly={true}
        className="-mb-2 mt-8 h-48"
      />
    </div>
  );
};

export default LineChartDisplay;
