import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';
import { PageName, getPageTabs, getPageTitle } from '../../../utils/layout';
import OutsideAlerter from '../../Common/OutsideAlerter';
import useReportStore from '../../../store/useReportStore';
import useVisibilityStore from '../../../store/useVisibilityStore';
import StatusCounter from '../../Report/StatusCounter';
import WeeklyStatusCounter from '../../Report/WeeklyStatusCounter';
import PerformanceCounter from '../../Report/PerformanceCounter';
import AnalyticsTab from '../../AnalyticsSections/AnalyticsTabs';
import useUtilityStore from '../../../store/useUtilityStore';
import useAuthStore from '../../../store/useAuthStore';
import api from '../../../api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import SwiperButtonNext from '../../SwiperButton/swiper';

interface Tab {
  label: string;
  path: string;
}

type OptionType = {
  value: string;
  label: string;
  icon: string;
  description: string;
  path: string;
};

const menuOptions: OptionType[] = [
  {
    value: 'ContactSupport',
    label: 'Contact Support',
    icon: 'LifeBuoy',
    description: 'Learn, fix a problem, and get answers to your questions.',
    path: '/feedback',
  },
];

interface DropDownMenuProps {
  closeDropdown: () => void;
}

interface UtilityStoreState {
  isHeaderHidden: boolean;
}

export const DropDownMenu: React.FC<DropDownMenuProps> = ({
  closeDropdown,
}) => {
  return (
    <div className="absolute right-8 top-10 flex flex-col p-3 bg-white w-[336px] border border-[#EAECF0] z-10 shadow-bs-card g-1 rounded-xl dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card">
      <div className="absolute right-4 top-[-10px] w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px]"></div>
      {menuOptions.map((option, index) => (
        <Link
          to={option.path}
          className="flex gap-4 items-start p-3 hover:bg-[#F3F7FA] dark:hover:bg-transparent cursor-pointer"
          key={index}
          onClick={closeDropdown}
        >
          <Icon name={option.icon} />
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-[#001129] dark:text-white">
              {option.label}
            </span>
            <small className="text-[#475467] dark:text-[#ADB0B2]">
              {option.description}
            </small>
          </div>
        </Link>
      ))}
    </div>
  );
};

const Header: React.FC = () => {
  const { isHeaderHidden } = useUtilityStore() as UtilityStoreState;
  const location = useLocation();
  const navigate = useNavigate();
  const { mode } = useThemeStore();
  const iconColor = mode === 'dark' ? 'white' : '';
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const { currentUser } = useAuthStore((state) => state);
  const [showTokenExpiredPopup, setShowTokenExpiredPopup] =
    useState<boolean>(false);

  const { loading } = useReportStore((state) => state);
  const { openVisibility, setOpenVisibility } = useVisibilityStore();
  let activePage = getPageTitle(location.pathname);

  const servicesParams = new URLSearchParams(location.search);
  const service = servicesParams.get('service');
  const tabs: Tab[] = getPageTabs(activePage as PageName, service as string);

  const active =
    'bg-[#F3F7FA] rounded-lg hover:bg-[#F3F7FA] dark:bg-[#F3F7FA] dark:text-black dark:hover:bg-white dark:border dark:border-[#313233]';

  const isMonthly = activePage === 'client reporting';
  const isWeekly = activePage === 'weekly reporting';
  const isPerformance = activePage === 'performance';
  const isClientDetails = location.pathname.includes('/client/details/');
  const isInternalAnalytics = activePage === 'client analytics';
  const isAnalytics = activePage === 'analytics';

  const handleVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const closeDropdown = () => {
    setOpenDropDown(false);
  };

  const hiddenHeaderOnPaths = [
    '/clients',
    '/client-users',
    '/omg-users',
    '/insights',
    '/goals',
  ];

  const shouldHideHeader = hiddenHeaderOnPaths.some(
    (path) => location.pathname === path
  );

  const headerTitle = {
    clients: { title: 'Clients', path: '/clients' },
    'omg settings': { title: 'Settings', path: '/omg-settings/profile' },
    'client access': { title: 'Client Access', path: '/client-access' },
    'client reporting': { title: 'Monthly', path: '/client-reporting' },
    'weekly reporting': { title: 'Weekly', path: '/weekly-reporting' },
    'change requests': { title: 'Change Requests', path: '/change-requests' },
    'change history': { title: 'Change History', path: '/change-history' },
    'client analytics': {
      title: 'Analytics',
      path: '/client/analytics/seo-ga4',
    },
    'client details': { title: 'Clients', path: '/clients' },
    performance: { title: 'Performance', path: '/performance' },
    'performance-insights': {
      title: 'Insights',
      path: '/performance-insights',
    },
    home: { title: 'Home', path: '/home' },
    'client users': { title: 'Users', path: '/client-users' },
    'omg users': { title: 'Users', path: '/omg-users' },
    insights: { title: 'Insights', path: '/insights' },
    chat: { title: 'Chat', path: '/chat' },
    goals: { title: 'Goals', path: '/goals' },
    dashboard: { title: 'Dashboard', path: '/dashboard' },
    analytics: { title: 'Analytics', path: '/analytics/seo-ga4' },
    reports: { title: 'Reports', path: '/reports' },
    referrals: { title: 'Referrals', path: '/referrals' },
    chatting: { title: 'Chat', path: '/chatting' },
    feedback: { title: 'Feedback', path: '/feedback' },
    settings: { title: 'Settings', path: '/settings/my-details' },
    tools: { title: 'Tools', path: '/tools-links' },
  };

  const renderTabs = tabs?.map((tab, index) => {
    const parts = location.pathname.split('/');
    const currentTabId = parts[parts.length - 2];
    const clientId = parts[parts.length - 1];
    return (
      <Link
        key={index}
        to={`${isClientDetails ? `client/details/${tab.path}/${clientId}` : `${tab.path}${location.search}`}`}
        className={`hidden sm:block text-sm font-inter font-[600] leading-6 sm:ml-5 px-2 ${
          location.pathname.substring(1) === tab.path ||
          currentTabId === tab.path
            ? active
            : 'text-gray-600 dark:text-white'
        }`}
      >
        {tab.label}
      </Link>
    );
  });

  const renderSwiperTabs = (
    <div className="relative block sm:hidden w-full">
      <Swiper
        className=""
        modules={[Pagination]}
        spaceBetween={2}
        slidesPerView={3}
      >
        <div className="bg-white">
          <SwiperButtonNext next={true}>
            <Icon
              name="ChevronRight"
              size={30}
              color={mode === 'dark' ? '#FFF' : '#000'}
            />
          </SwiperButtonNext>
        </div>
        {tabs?.map((tab, index) => {
          const parts = location.pathname.split('/');
          const currentTabId = parts[parts.length - 2];
          const clientId = parts[parts.length - 1];
          const isActive =
            location.pathname.substring(1) === tab.path ||
            currentTabId === tab.path;

          return (
            <SwiperSlide key={index} className="mySwiper px-8">
              <Link
                to={`client/details/${tab.path}/${clientId}`}
                className={`w-fit text-sm font-inter font-[600] leading-6 px-2 ${
                  isActive ? active : 'text-gray-600 dark:text-white'
                }`}
              >
                {tab.label}
              </Link>
            </SwiperSlide>
          );
        })}
        <div className="bg-white">
          <SwiperButtonNext prev={true}>
            <Icon
              name="ChevronLeft"
              size={30}
              color={mode === 'dark' ? '#FFF' : '#000'}
            />
          </SwiperButtonNext>
        </div>
      </Swiper>
    </div>
  );

  const renderStatusCounter = isMonthly ? (
    <div className="flex items-center justify-between w-full">
      <StatusCounter loading={loading} clickable={true} />
      <div className="hidden sm:block">
        <button
          className="flex items-center py-2 px-3.5 gap-2 rounded-lg border border-[#808EA2]"
          onClick={handleVisibilityClick}
        >
          <span className="text-[#001C44] text-sm font-inter font-semibold dark:text-white">
            Columns
          </span>
          <Icon name="offEye" color={mode === 'dark' ? '#fff' : iconColor} />
        </button>
      </div>
    </div>
  ) : isWeekly ? (
    <WeeklyStatusCounter />
  ) : isPerformance ? (
    <PerformanceCounter />
  ) : isInternalAnalytics ? (
    <AnalyticsTab isInternalAnalytics={true} isPublic={false} />
  ) : isAnalytics ? (
    <AnalyticsTab isInternalAnalytics={false} isPublic={false} />
  ) : null;

  const callAfterPageRefresh = async () => {
    if (currentUser?.gurulytics_role?.level === 'omg') {
      const getExpiredFbToken = await api.Meta.getExpiredToken();
      setShowTokenExpiredPopup(false);
      if (getExpiredFbToken?.data?.length > 0) {
        setShowTokenExpiredPopup(true);
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('isPageRefreshed') === 'true') {
      callAfterPageRefresh();
      sessionStorage.setItem('isPageRefreshed', 'false');
    }
    sessionStorage.setItem('isPageRefreshed', 'true');
  }, []);

  return (
    <>
      <div
        className={`${
          shouldHideHeader ? 'hidden sm:flex' : 'flex'
        } items-center justify-between w-full border-b h-12 sm:h-16 sm:px-6 sticky top-[64px] sm:top-0 bg-[#FFF] z-[96] dark:bg-[#0C111D] shadow-sm transition-opacity duration-500 ease-in-out ${
          isHeaderHidden ? 'opacity-0 sm:opacity-100' : 'opacity-100'
        }`}
      >
        <div className="flex items-center w-full">
          <div className="flex justify-between items-center w-full">
            <div>
              <Link to={headerTitle[activePage]?.path}>
                <div className="flex items-center h-16">
                  {location.pathname.includes('/reports/details/') && (
                    <Link to={'/reports'} className="cursor-pointer">
                      <Icon name="arrowLeft" />
                    </Link>
                  )}
                  <span className="ml-5 lg:mx-5 lg:text-[18px] hidden md:block font-bold font-inter text-[#001C44] dark:text-white">
                    {headerTitle[activePage]?.title}
                  </span>
                  {/* <Icon color={iconColor} name="chevronRight" size={16} /> */}
                </div>
              </Link>
            </div>
            <div className="w-full flex justify-center sm:justify-start sm:ml-2">
              {renderTabs}
              {renderSwiperTabs}
              {renderStatusCounter}
            </div>
          </div>
        </div>

        {showTokenExpiredPopup && (
          <div className="absolute left-0 sm:left-auto right-0 sm:right-0 top-2 sm:top-auto w-auto flex items-center justify-center border-b z-[99] bg-[#001C44] dark:bg-[#191919] rounded-lg border-none mx-2 py-2 px-4">
            <div className="flex w-full text-center items-center justify-center text-[#FFB545] text-xs sm:text-sm font-inter font-[600]">
              <span
                className="inline-block cursor-pointer hover:underline"
                onClick={() => {
                  navigate('/omg-settings/security');
                  setShowTokenExpiredPopup(false);
                }}
              >
                Your meta token has expired. Please reauthenticate.
              </span>
              <span
                className="inline-block cursor-pointer ml-1"
                onClick={() => {
                  setShowTokenExpiredPopup(false);
                }}
              >
                <Icon name="Xclose" size={15} color="#FFB545" />
              </span>
            </div>
          </div>
        )}
        {activePage === 'client reporting' ? null : (
          <div className="relative hidden sm:flex w-20 justify-between gap-2">
            <div
              onClick={() => setOpenDropDown(!openDropDown)}
              className="cursor-pointer bg-[#E3F4FF] rounded-lg"
            >
              <Icon color={iconColor} name="info" size={40} />
            </div>
            <div className="cursor-pointer bg-[#E3F4FF] rounded-lg">
              <Icon color={iconColor} name="bell" size={40} />
            </div>
            {openDropDown && (
              <OutsideAlerter toggle={setOpenDropDown}>
                <DropDownMenu closeDropdown={closeDropdown} />
              </OutsideAlerter>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
