import React, { useState } from 'react';
import CustomModal from '../../components/CustomModal';
import GA4Modal from '../../components/Modals/Integration/ga4';
import BingAdsModal from '../../components/Modals/Integration/bingAds';
import GoogleAdsModal from '../../components/Modals/Integration/googleAds';
import FBModal from '../../components/Modals/Integration/fb';
import TeamworkModal from '../../components/Modals/Integration/teamwork';
import RankRangerModal from '../../components/Modals/Integration/rankRanger';
import AhrefsModal from '../../components/Modals/Integration/ahrefs';
import SemrushModal from '../../components/Modals/Integration/semrush';
import HubspotModal from '../../components/Modals/Integration/hubspot';
import GoogleAnalytics from '../../assets/images/googleAnalytics.png';
import GoogleSearchConsole from '../../assets/images/GoogleSearchConsole.png';
import GoogleAd from '../../assets/images/googleAd.png';
import FacebookAds from '../../assets/images/facebookAds.png';
import TeamWork from '../../assets/images/teamworkProject.png';
import Hubspot from '../../assets/images/HubSpot1.png';
import SemRush from '../../assets/images/semRush1.png';
import RankRanger from '../../assets/images/rankRanger.png';
import BingAds from '../../assets/images/bingSEO.png';
import aHrefs from '../../assets/images/aHrefs.png';
import IntegrationCard from '../../components/IntegrationCard';
import GoogleConsole from '../../components/Modals/Integration/googleSearchConsole';
import { isStaging } from '../../utils';

interface IntegrationsProps {
  selectedClient?: any;
}

const Integrations: React.FC<IntegrationsProps> = ({ selectedClient }) => {
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [selectedService, setSelectedService] = useState({ title: '' });

  const integrationItems = [
    {
      title: 'Google Analytics 4',
      description:
        "Create, manage, and track paid advertising campaigns on Google's platform.",
      connected: false,
      image: GoogleAnalytics,
      link: '/client/analytics/seo-ga4',
      status: selectedClient?.google_analytics?.ga4?.status,
      data: selectedClient?.google_analytics?.ga4,
      id: selectedClient?.google_analytics?.ga4?.property_id,
      show: true,
    },
    {
      title: 'Google Ad Words',
      description:
        "Enhance website visibility on Google's search results through optimization techniques.",
      connected: false,
      image: GoogleAd,
      link: '/client/analytics/paid-search',
      status: selectedClient?.google_ads?.status,
      data: selectedClient?.google_ads,
      id: selectedClient?.google_ads?.customer_id,
      show: true,
    },
    {
      title: 'Microsoft Ads',
      description:
        "Create, manage, and track paid advertising campaigns on Google's platform.",
      connected: false,
      image: BingAds,
      link: '/client/analytics/microsoft-ads',
      status:
        selectedClient?.bing_ads?.status ??
        selectedClient?.bing_ads?.AccountLifeCycleStatus,
      data: selectedClient?.bing_ads,
      id: selectedClient?.bing_ads?.customer_id,
      show: true,
    },
    {
      title: 'Google Search Console',
      description:
        "Create, manage, and track paid advertising campaigns on Google's platform.",
      connected: false,
      image: GoogleSearchConsole,
      status: selectedClient?.google_search_console?.status,
      data: selectedClient?.google_search_console,
      id: selectedClient?.google_search_console?.site_url,
      show: true,
    },
    {
      title: 'Facebook Ad',
      description:
        'Manage advertising campaigns effectively across Facebook and Instagram platforms',
      connected: false,
      image: FacebookAds,
      link: '/client/analytics/fb-ads',
      status: selectedClient?.facebook_ads?.status,
      data: selectedClient?.facebook_ads,
      id: selectedClient?.facebook_ads?.ads_id,
      show: true,
    },
    {
      title: 'Teamwork Project',
      description:
        "Launch and track advertising campaigns targeting TikTok's youthful audience.",
      connected: false,
      image: TeamWork,
      link: '',
      status: selectedClient?.teamwork?.status,
      data: selectedClient?.teamwork,
      id: selectedClient?.teamwork?.projects_ids[0],
      show: true,
    },
    {
      title: 'Semrush',
      description:
        "Create and analyze visual advertising campaigns on Pinterest's platform.",
      connected: false,
      image: SemRush,
      link: '/client/analytics/seo-ga4',
      status: selectedClient?.semrush?.status,
      data: selectedClient?.semrush,
      show: true,
    },
    {
      title: 'Rank Ranger',
      description:
        'Automate the buying and selling of online advertising spaces efficiently.',
      connected: false,
      image: RankRanger,
      link: '/client/analytics/seo-ga4',
      status: selectedClient?.rank_ranger?.status,
      data: selectedClient?.rank_ranger,
      id: selectedClient?.rank_ranger?.campaign_id,
      show: true,
    },
    {
      title: 'Ahrefs',
      description:
        'Utilize AI-driven insights for programmatic advertising through Stack Adapt.',
      connected: false,
      image: aHrefs,
      link: '/client/analytics/seo-ga4',
      status: selectedClient?.ahrefs?.status,
      data: selectedClient?.ahrefs,
      show: true,
    },
    {
      title: 'Hubspot',
      description:
        'Develop targeted advertising campaigns for a professional audience on LinkedIn.',
      connected: false,
      image: Hubspot,
      link: '',
      status: 'activated',
      data: selectedClient?.hubspot,
      id: selectedClient?.hubspot?.company_id,
      show: true,
    },
  ];
  const handleIntegrationModal = (data: any) => {
    setSelectedService(data);
    setOpenConnectModal(true);
  };
  return (
    <div>
      <div className="flex justify-center bg-white px-3 sm:px-[28px] pb-[14px] pt-[28px] dark:bg-gray-800 rounded-lg">
        <div className="w-full flex flex-wrap justify-start sm:gap-2 md:gap-4 dark:bg-gray-800 ">
          <IntegrationCard
            integrationItems={integrationItems?.filter((item) => item.show)}
            selectedClient={selectedClient}
            openModal={(data: any) => handleIntegrationModal(data)}
          />
        </div>
      </div>
      <CustomModal
        open={openConnectModal}
        onClose={() => setOpenConnectModal(false)}
      >
        {/* ga 4 Analytics modal */}
        {selectedService?.title === 'Google Analytics 4' && (
          <GA4Modal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {/* Google Ad Words modal */}
        {selectedService?.title === 'Google Ad Words' && (
          <GoogleAdsModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {/* Microsoft Ads modal */}
        {selectedService?.title === 'Microsoft Ads' && (
          <BingAdsModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {/* FB modal */}
        {selectedService?.title === 'Facebook Ad' && (
          <FBModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Teamwork Project' && (
          <TeamworkModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Rank Ranger' && (
          <RankRangerModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Ahrefs' && (
          <AhrefsModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Semrush' && (
          <SemrushModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Hubspot' && (
          <HubspotModal
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
        {selectedService?.title === 'Google Search Console' && (
          <GoogleConsole
            data={selectedService}
            onClose={() => setOpenConnectModal(false)}
          />
        )}
      </CustomModal>
    </div>
  );
};
export default Integrations;
