import * as React from "react"

const GridMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={6}
    fill="none"
    {...props}
  >
    <path
      fill="#98A2B3"
      d="M12.25 1.5c0 .383-.3.656-.656.656H.656A.632.632 0 0 1 0 1.5C0 1.145.273.844.656.844h10.938c.355 0 .656.3.656.656Zm0 3.5c0 .383-.3.656-.656.656H.656A.632.632 0 0 1 0 5c0-.355.273-.656.656-.656h10.938c.355 0 .656.3.656.656Z"
    />
  </svg>
)
export default GridMenu
