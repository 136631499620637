import { useLocation } from 'react-router-dom';
import ComingSoon from '../../components/ComingSoon';
import PageHeader from '../../components/Dashboard/PageHeader';
import Security from './Security';
import ChangeRequests from './ChangeRequests';
import Permissions from './Permissions';
import Syncing from './Syncing';
import ProfileSettings from './Profile';
import LogsSettings from './Logs';

export default function OmgSettings() {
  const location = useLocation();

  let ContentComponent: any;
  location.pathname === '/omg-settings/security'
    ? (ContentComponent = Security)
    : location.pathname === '/omg-settings/change-requests'
      ? (ContentComponent = ChangeRequests)
      : location.pathname === '/omg-settings/logs'
        ? (ContentComponent = LogsSettings)
        : location.pathname === '/omg-settings/syncing'
          ? (ContentComponent = Syncing)
          : location.pathname === '/omg-settings/profile'
            ? (ContentComponent = ProfileSettings)
            : (ContentComponent = null);

  let subtitle;

  location.pathname === '/omg-settings/syncing'
    ? (subtitle =
        'Keep your content fresh and aligned across the platform with Page Syncs. Automate updates and synchronization to ensure consistency and accuracy in real-time.')
    : location.pathname === '/omg-settings/profile'
      ? (subtitle = 'Update your photo and personal details here.')
      : (subtitle = '');

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const title = capitalizeFirstLetter(
    location.pathname
      .replace('omg-settings/', '')
      .replace('/', '')
      .replace('-', ' ')
  );

  const isLogs = title === 'Logs';

  return (
    <div className="flex flex-col px-2">
      {!isLogs && (
        <PageHeader
          title={title}
          subtitle={subtitle}
          customClass="px-8"
          hideDate
          hideDomain
        />
      )}
      {ContentComponent ? <ContentComponent /> : <ComingSoon />}
    </div>
  );
}
