import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AreaChart, Card } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent';
import InfoTooltip from '../AnalyticsInfoTooltip';
import TrendTxt from '../../Common/TrendTxt';
import TrendTooltip from '../TrendTooltip';
import {
  formatNumber,
  formatToCurrency,
  parseDate,
} from '../../../utils/common';
import { useThemeStore } from '../../../store/useThemeStore';
import useMetaStore from '../../../store/useMetaStore';
import useClientStore from '../../../store/useClientStore';
import moment from 'moment';
import NoDataError from '../../NoDataError';
import Tooltip from '../../HelpTooltip';
import NoDataErrorRetry from '../../NoDataRetry';

interface Props {
  isLoading?: boolean;
  chatData?: any;
  data?: any;
  category?: string[];
  title?: string;
  info?: string;
  prevPeriod?: any;
  previousYear?: any;
  totalValue?: any;
  defaultChart?: string;
  dollarItems?: string[];
  percentageItems?: string[];
  hasDollar?: boolean;
  hasPercent?: boolean;
  status?: boolean;
  requestAccess?: any;
  error?: string;
  noAccessSubtitle?: string;
  icon?: string;
  showCurrentMonth?: boolean;
  accountInsights?: boolean;
  customDataTooltip?: boolean;
  customTooltipAlignRight?: boolean;
  propertyId?: string;
  retryMethod?: () => void;
}
const iconSizes = {
  ahref: 15,
  googleSearch: 20,
};

type CustomTooltipType = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

const AreaCardChart: React.FC<Props> = ({
  title,
  chatData,
  category,
  info,
  prevPeriod,
  previousYear,
  totalValue,
  hasDollar,
  hasPercent,
  isLoading,
  error,
  status,
  requestAccess,
  noAccessSubtitle,
  icon,
  showCurrentMonth,
  accountInsights,
  customDataTooltip = false,
  customTooltipAlignRight = false,
  propertyId,
  retryMethod,
}) => {
  const { fetchAccountInsights, resetMetaStore } = useMetaStore(
    (state) => state
  );
  const { selectedClient } = useClientStore((state) => state);
  const getLocalStorageDate = (key: string) => {
    const rawDate = localStorage?.getItem(key);
    return rawDate ? JSON.parse(rawDate) : null;
  };

  const formatToYYYYMMDD = (date: any) => {
    return date && moment(date).format('YYYY-MM-DD');
  };

  const location = useLocation();
  const [showMonthToDate, setShowMonthToDate] = useState(false);

  const seoStartDate = parseDate(localStorage.getItem('seoStartDate'));
  const seoEndDate = parseDate(localStorage.getItem('seoEndDate'));
  const startDate = parseDate(localStorage.getItem('startDate'));
  const endDate = parseDate(localStorage.getItem('endDate'));

  const isSeoGa4 = location.pathname.includes('/analytics/seo-ga4');

  useEffect(() => {
    const currentMoment = moment();
    const isCycle2 = selectedClient?.cycle === 'Cycle 2';

    const isValidAndCurrentOrFutureMonth = (date) => {
      const momentDate = date ? moment(date) : null;
      if (!momentDate || !momentDate.isValid()) {
        return false;
      }
      if (isCycle2) {
        // For Cycle 2, check for the exact date
        return momentDate.isSameOrAfter(currentMoment, 'day');
      } else {
        // For other cycles, check for the month
        return momentDate.isSameOrAfter(currentMoment, 'month');
      }
    };

    const isCurrentOrFutureMonth = isSeoGa4
      ? seoEndDate && isValidAndCurrentOrFutureMonth(seoEndDate)
      : (startDate && isValidAndCurrentOrFutureMonth(startDate)) ||
        (endDate && isValidAndCurrentOrFutureMonth(endDate));

    setShowMonthToDate(isCurrentOrFutureMonth);
  }, [
    seoStartDate,
    seoEndDate,
    startDate,
    endDate,
    selectedClient,
    location.pathname,
    isSeoGa4,
  ]);

  let dates = {
    startDate: formatToYYYYMMDD(getLocalStorageDate('startDate')),
    endDate: formatToYYYYMMDD(getLocalStorageDate('endDate')),
  };

  // const fetchFbInsights = useCallback(
  //   async (startDate: Date | string, endDate: Date | string) => {
  //     console.log('selectedclient called')
  //     const data = {
  //       clientId: selectedClient.id,
  //       since: startDate,
  //       until: moment(endDate).format('YYYY-MM-DD'),
  //       timeIncrement: 'monthly',
  //       level: 'campaign',
  //       limit: 100,
  //     };
  //     if (
  //       selectedClient.facebook_ads.ads_id &&
  //       ['activated', 'active'].includes(selectedClient.facebook_ads.status)
  //     ) {
  //       try {
  //         await Promise.all([fetchAccountInsights(data)]);
  //       } catch (error) {
  //         console.log(error, 'error');
  //       }
  //     } else {
  //       resetMetaStore([
  //         'accountInsights',
  //         'campaignInsights',
  //         'adInsights',
  //         'adsetInsights',
  //       ]);
  //     }
  //   },
  //   [fetchAccountInsights, resetMetaStore, selectedClient]
  // );

  const { mode } = useThemeStore();
  const iconSize = iconSizes[icon] || 25;
  const dataFormatter = useCallback(
    (number: number | string, hasDollar?: boolean, hasPercent?: boolean) => {
      return hasDollar
        ? formatToCurrency(number)
        : formatNumber(number, hasPercent);
    },
    []
  );

  const customTooltip = (props: CustomTooltipType) => {
    const { payload, active, label } = props;
    if (!active || !payload) return null;

    const payloadData = payload[0]?.payload;

    const filteredPayloadData = Object.entries(payloadData).filter(
      ([key, value]: [string, any]) => key !== 'date' && value !== 0
    );

    return (
      <div className="w-[280px] absolute top-[-8rem] right-[-22rem] rounded-tremor-default border border-tremor-border bg-white bg-opacity-80 backdrop-blur-sm  p-2 text-tremor-default shadow-tremor-dropdown dark:bg-[#222732] z-[99999]">
        <div className="flex flex-1 space-x-2.5">
          <div
            className={`flex w-1.5 flex-col bg-[${payload[0]?.color}] rounded`}
          />
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis dark:text-white">
              {label}
            </p>
            {filteredPayloadData?.map(
              ([key, value]: [string, any], index: number) => (
                <div
                  key={index}
                  className="flex items-center justify-between space-x-6"
                >
                  <span className="text-tremor-content font-bold text-xs max-w-[10rem] dark:text-gray-300">
                    {key}
                  </span>
                  <span className="font-medium tabular-nums text-tremor-content-emphasis text-xs dark:text-[#155CF6]">
                    {isNaN(value) ? 0 : formatNumber(value)}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div
        role="status"
        className={`w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700  ${showCurrentMonth ? ' h-[374px]' : ' h-[374px]'}`}
      >
        <div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
          <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="flex items-baseline mt-[112px]">
          <div className="w-full bg-gray-200 rounded-t-lg h-44 dark:bg-gray-700"></div>
          <div className="w-full h-40 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full h-44 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-44 ms-6 dark:bg-gray-700"></div>
          <div className="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-700"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    return (
      <div className="dark:bg-[#222732] w-full relative">
        {chatData?.length === 0 || !chatData || status === false ? (
          <Card className="border-none shadow-none ring-0 lg:ring-1 p-[16px] h-[374px]">
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center mb-1">
                <h2 className="text-[14px] text-[#001C44] dark:text-white font-bold">
                  {title ? title : 'Organic Engaged Sessions'}
                </h2>
                <InfoTooltip content={info ? info : ''} position="top">
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <Icon name={`${icon}`} size={iconSize} />
            </div>
            <div
              className={`flex flex-col items-center justify-center w-full my-8 h-[265px] gap-1`}
            >
              {/* accountInsights 
                  ? fetchFbInsights(dates.startDate, dates.endDate) 
                  : typeof retryMethod === 'function' ? retryMethod()
                  : null */}
              <NoDataErrorRetry
                error={error}
                noAccessSubtitle={noAccessSubtitle}
                retry={error && error !== ''}
                handleRetry={() =>
                  typeof retryMethod === 'function' ? retryMethod() : undefined
                }
                requestAccess={status === false && !error}
                handleRequestAccess={() => requestAccess()}
              />
              {/* <NoDataError error={error} noAccessSubtitle={noAccessSubtitle} />
              {error && error !== '' && (
                <div
                  className="mt-4l py-2 px-5 font-bold text-sm cursor-pointer underline dark:text-white"
                  onClick={() => {
                    if (accountInsights) {
                      fetchFbInsights(dates.startDate, dates.endDate);
                    }
                  }}
                >
                  Retry
                </div>
              )} */}

              {/* {status === false && !error && (
                <div
                  className="mt-4 bg-[#D0EDFF] rounded-full py-2 px-5 font-bold text-sm cursor-pointer"
                  onClick={requestAccess}
                >
                  Request Access
                </div>
              )} */}
            </div>
          </Card>
        ) : (
          <Card className="border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-[410px] sm:h-[374px]">
            <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
              <div className="flex gap-2 items-center mb-1">
                <h2 className="text-[14px] text-[#001C44] dark:text-white font-bold">
                  {title ? title : 'Organic Engaged Sessions'}
                </h2>
                <InfoTooltip content={info ? info : ''} position="top">
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              {propertyId ? (
                <Tooltip
                  content={propertyId ? propertyId : 'N/A'}
                  position="top"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={`${icon}`} size={iconSize} />
                  </div>
                </Tooltip>
              ) : (
                <div className="pt-1">
                  <Icon name={`${icon}`} size={iconSize} />
                </div>
              )}
            </div>
            <h3 className="text-[30px] font-semibold leading-8 mb-2 dark:text-white">
              {totalValue ? totalValue : '0'}
            </h3>
            {showCurrentMonth ? (
              <div className="bg-[#222A68] dark:bg-[#D0EDFF] w-fit px-2.5 py-1 rounded-full text-white mb-5">
                <p className="font-inter text-[10px] dark:text-[#001C44]">
                  Month to Date 1
                </p>
              </div>
            ) : (
              <>
                {showMonthToDate ? (
                  <div className="bg-[#222A68] dark:bg-[#D0EDFF] w-fit px-2.5 py-1 rounded-full text-white mb-5">
                    <p className="font-inter text-[10px] dark:text-[#001C44]">
                      Month to Date 2
                    </p>
                  </div>
                ) : null}
              </>
            )}
            <div className="flex border-b border-tremor-border dark:border-dark-tremor-border w-full mb-4">
              {!showMonthToDate && !showCurrentMonth && (
                <>
                  <div>
                    <TrendTooltip percentage={'15.26'}>
                      <TrendTxt
                        type="period"
                        title="Prev Period"
                        size="small"
                        value={prevPeriod ? prevPeriod : '0'}
                      />
                    </TrendTooltip>
                  </div>
                  <div className="ml-[6px]">
                    <TrendTooltip percentage={'3.35'}>
                      <TrendTxt
                        type="year"
                        title="Prev Year"
                        size="small"
                        value={previousYear ? previousYear : '0'}
                      />
                    </TrendTooltip>
                  </div>
                </>
              )}
            </div>
            {customDataTooltip ? (
              <div className="w-full">
                <AreaChart
                  className="h-56"
                  data={chatData}
                  showYAxis={false}
                  showLegend={false}
                  showAnimation={false}
                  showGradient={true}
                  startEndOnly
                  index="date"
                  categories={category}
                  customTooltip={customTooltip}
                  colors={mode === 'light' ? ['#0029FF'] : ['#fff']}
                  noDataText="No Data"
                  valueFormatter={(value) =>
                    dataFormatter(value, hasDollar, hasPercent)
                  }
                />
              </div>
            ) : (
              <div className="w-full">
                <AreaChart
                  className="h-56"
                  data={chatData}
                  showYAxis={false}
                  showLegend={false}
                  showAnimation={false}
                  showGradient={true}
                  startEndOnly
                  index="date"
                  categories={category}
                  colors={mode === 'light' ? ['#0029FF'] : ['#fff']}
                  noDataText="No Data"
                  valueFormatter={(value) =>
                    dataFormatter(value, hasDollar, hasPercent)
                  }
                />
              </div>
            )}
          </Card>
        )}
      </div>
    );
  }
};

export default AreaCardChart;
