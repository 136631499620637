import Header from '../components/Layout/Header';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import useAuthStore from '../store/useAuthStore';
import { useNavigate, useLocation } from 'react-router';
import Sidebar from '../components/Layout/Sidebar';
import { useThemeStore } from '../store/useThemeStore';
import Icon from '../assets/icons/SvgComponent';
import { isStaging } from '../utils';

interface SubItem {
  label: string;
  path: string;
  icon?: string;
}
interface Item {
  icon: string;
  label: string;
  path?: string;
  rightIcon?: string;
  subItems?: SubItem[];
  show?: boolean;
}
const Layout = () => {
  const mode = useThemeStore((state) => state.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { loginAsUser, user } = useAuthStore((state) => state);
  useEffect(() => {
    if (loginAsUser && user?.role?.level === 'omg') {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [location]);

  const items: Item[] = [
    // {
    //   icon: 'dashboard',
    //   label: 'Home',
    //   path: '/home',
    // },
    {
      icon: 'User01',
      label: 'Clients',
      show: true,
      path: '',
      subItems: [
        {
          label: 'Client Profiles',
          path: '/clients',
          icon: 'ClientProfile',
        },
        {
          label: 'Change Approvals',
          path: '/change-requests',
          icon: 'ChangeApprovals',
        },
        {
          label: 'Change History',
          path: '/change-history',
          icon: 'ChangeHistory',
        },
        {
          label: 'Change Reports',
          path: '/change-reports',
          icon: 'ChangeReports',
        },
      ],
    },
    {
      icon: 'feedback',
      label: 'Users',
      show: true,
      path: '',
      rightIcon: 'ChevronDown',
      subItems: [
        {
          label: 'Clients',
          path: '/client-users',
          icon: 'ClientUsers',
        },
        {
          label: 'OMG',
          path: '/omg-users',
          icon: 'OmgUsers',
        },
      ],
    },
    {
      icon: 'LineChartUp',
      label: 'Analytics',
      show: true,
      path: '/client/analytics/seo-ga4',
    },

    // {
    //   icon: 'tool02',
    //   label: 'Change Requests',
    //   path: '/change-requests',
    // },
    {
      icon: 'reports',
      label: 'Reporting',
      show: true,
      path: '',
      rightIcon: 'ChevronDown',
      subItems: [
        {
          label: 'Monthly',
          path: '/client-reporting',
          icon: 'monthlyReport',
        },
        {
          label: 'Weekly',
          path: '/weekly-reporting',
          icon: 'weeklyReport',
        },
        {
          label: 'Insights',
          path: '/insights',
          icon: 'InsightReport',
        },
      ],
    },
    // {
    //   icon: 'target',
    //   label: 'Goals',
    //   show: true,
    //   path: '/goals',
    // },
    {
      icon: 'target',
      label: 'Goals',
      show: true,
      path: '/goals',
    },
    {
      icon: 'performance',
      label: 'Performance',
      show: true,
      path: '',
      subItems: [
        {
          label: 'Monthly',
          path: '/performance',
          icon: 'monthlyReport',
        },
        // Hide for live, show if staging => isStaging()
        {
          label: 'Insights',
          path: '/insights-performance',
          icon: 'InsightReport',
        },
      ],
    },

    // {
    //   icon: 'chat',
    //   label: 'Chat',
    //   path: '/chat',
    // },
    // {
    //   icon: 'Tool02',
    //   label: 'Tools',
    //   // path: '/tools',
    //   path: '',
    //   rightIcon: 'ChevronDown',
    //   subItems: [
    //     {
    //       label: 'Client Access',
    //       path: 'client-access/all-clients',
    //     },
    //     {
    //       label: 'Cient Management',
    //       path: '#',
    //     },
    //     {
    //       label: 'Tech Audit',
    //       path: '#',
    //     },
    //     {
    //       label: 'Competitor Audit',
    //       path: '#',
    //     },
    //   ],
    // },
    {
      icon: 'settings',
      label: 'Settings',
      show: true,
      path: '/omg-settings/profile',
      rightIcon: 'ChevronDown',
      // subItems: [
      //   {
      //     label: 'Admin',
      //     path: '/omg-settings/profile',
      //   },
      //   {
      //     label: 'Audit Log',
      //     path: '#',
      //   },
      // ],
    },
    {
      icon: 'Tools',
      label: 'Tools',
      show: true,
      path: '',
      subItems: [
        {
          label: 'Links lookup',
          path: '/tools-links',
          icon: 'LinksLookup',
        },
      ],
    },
  ];
  useEffect(() => {
    if (mode === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [mode]);

  return (
    <div className="flex h-full w-full bg-[#F9FAFB] relative overflow-hidden">
      <Helmet>
        <title>Gurulytics</title>
        <meta name="description" content="Gurulytics" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="max-h-screen dark:bg-[#0C111D] bg-[#D2EEFF]">
        <div className="relative z-[99]">
          <Sidebar items={items?.filter((item) => item.show)} />
          {/* <div
          className="absolute top-[16px] right-[-15px] z-[100] cursor-pointer md:block lg:block sm:hidden"
          onClick={() => toggleSidebar()}
        >
          {!sidebarCollapsed ? (
            <div className="flex flex-col items-center gap-[2px] justify-center sm:hidden md:flex lg:flex rounded-full bg-[#F3F7FA] h-8 w-8 dark:bg-gray-700">
              <Icon name="ToggleDash" color={iconColor} />
              <Icon name="ToggleDashArrowRight" color={iconColor} />
              <Icon name="ToggleDash" color={iconColor} />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-[2px] justify-center sm:hidden md:flex lg:flex rounded-full bg-[#F3F7FA] h-8 w-8 dark:bg-gray-700">
              <Icon name="ToggleDash" color={iconColor} />
              <Icon name="ToggleDashArrowLeft" color={iconColor} />
              <Icon name="ToggleDash" color={iconColor} />
            </div>
          )}
        </div> */}
        </div>
      </div>
      <div
        ref={scrollRef}
        className="flex-col w-full overflow-auto bg-[#D2EEFF] dark:bg-[#0C111D] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8"
      >
        <Header />
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
